import { getLanguageFromPath } from '~/helpers/language'

interface EntityProps {
  uuid: string | null
  canEdit: boolean | null
}

/**
 * Builds the props for the <BlokkliProvider> component.
 */
export default function (
  props: EntityProps,
  entityBundle = 'page',
  entityType = 'node',
) {
  const route = useRoute()
  return computed(() => {
    const language = getLanguageFromPath(route.path)
    return {
      entityUuid: props.uuid || '',
      canEdit: !!props.canEdit,
      entityType,
      entityBundle,
      language,
      tag: 'main',
    }
  })
}
