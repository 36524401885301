import {
  DrupalRouteEntityFragment,
  MetatagAttributeFragment,
  MetatagFragment,
  RouteQuery,
  UseDrupalRouteFragment,
} from '#graphql-operations'
import type { Head } from '@unhead/schema'
import { Link, Meta } from '@unhead/schema'
import type { HookResult } from '@nuxt/schema'

/**
 * Get the page title from the Drupal metatags.
 */
export function getTitle(tag: MetatagFragment): string | undefined {
  if (tag.id !== 'title') {
    return
  }

  const value = tag.attributes.find((v) => v.key === 'content')
  if (!value) {
    return
  }

  return value.value
}

/**
 * Build the vue-meta object given the Drupal metatag attributes array.
 *
 * The input is an array of e.g.
 * [{ key: 'one', value: 'foo' }, { key: 'two', value: 'bar'  }]
 *
 * The output is an object where each of the key/value pairs is reduced to a
 * single object:
 * { one: 'foo', two: 'bar' }
 */
export function getTagObject(
  attributes: MetatagAttributeFragment[],
): Link | Meta {
  return attributes.reduce((acc, v) => {
    acc[v.key] = v.value
    return acc
  }, {} as any)
}

/**
 * Maps the Drupal metatags to vue-meta compatible definitions.
 */
export function getTags(metatags: MetatagFragment[]): Head {
  try {
    const link: Link[] = []
    const meta: Meta[] = []
    let title: string | undefined = ''

    for (let i = 0; i < metatags.length; i++) {
      const tag = metatags[i]
      const tagTitle = getTitle(tag)
      if (tagTitle) {
        title = tagTitle
      } else {
        const item = getTagObject(tag.attributes)
        if (tag.tag === 'link') {
          link.push(item)
        } else if (tag.tag === 'meta') {
          meta.push(item)
        }
      }
    }

    return { link, meta, title }
  } catch (e) {
    console.log(e)
  }
  return {}
}

type UseDrupalRouteOptions = {
  /**
   * Don't throw error when route is not found.
   *
   * Use this for routes not serving an entity.
   */
  noError?: boolean
}

type UseDrupalRoute<T> = {
  /**
   * The user-specific fields for the entity.
   */
  entity: T

  /**
   * The Drupal route information.
   */
  drupalRoute?: {
    name: string | undefined
    entity?: DrupalRouteEntityFragment['entity'] | undefined
  }

  /**
   * The mapped meta tags.
   */
  head?: Head
}

// Overload signature to make the return type nullable when setting noError to
// true, because it allows the composable to return if no route entity is
// available.
export function useDrupalRoute<T = {}>(
  query: UseDrupalRouteFragment | RouteQuery | null,
  options: { noError: true },
): Promise<UseDrupalRoute<T | undefined>>

// Overload signature to make the return type non nullable without options or
// when the option noError is false, because the code ensures that the return
// type is always going to be the passed in generic type.
export function useDrupalRoute<T = {}>(
  query: UseDrupalRouteFragment | RouteQuery | null,
  options?: { noError?: false },
): Promise<UseDrupalRoute<T>>

/**
 * Composable that handles the Drupal routing for 404, redirects, metatags and
 * entities.
 *
 * The composable must be called directly in the top level of the <script
 * setup> code.
 */
export async function useDrupalRoute<T = {}>(
  query: UseDrupalRouteFragment | RouteQuery | null,
  options?: UseDrupalRouteOptions,
): Promise<UseDrupalRoute<T | undefined>> {
  const app = useNuxtApp()

  // If the value is null it means the page does not exist.
  if (!query || !query.useDrupalRoute) {
    // If no error is requested, return.
    if (options?.noError) {
      return { entity: undefined, drupalRoute: undefined }
    }

    // Throw an error.
    throw createError({
      statusCode: 404,
      statusMessage: 'Page not found',
      fatal: true,
    })
  }

  // Handle redirects.
  if (query.useDrupalRoute.__typename === 'RedirectUrl') {
    return navigateTo(query.useDrupalRoute.path, {
      redirectCode: query.useDrupalRoute.redirect?.statusCode ?? 302,
      replace: true,
    }) as any
  }

  // Handle metatags.
  const head =
    'metatags' in query.useDrupalRoute
      ? getTags(query.useDrupalRoute.metatags)
      : undefined
  if (head) {
    useHead(head)
  }

  const drupalRoute: UseDrupalRoute<any>['drupalRoute'] = {
    name:
      'routeName' in query.useDrupalRoute
        ? query.useDrupalRoute.routeName
        : undefined,
  }

  // At this point we have an entity and the route can be rendered.
  // Implementors might still throw an error afterwards, e.g. when the route
  // belongs to an entity that is not supported in the frontend.
  if (
    'route' in query &&
    query.route?.__typename &&
    (query.route.__typename === 'EntityCanonicalUrl' ||
      query.route.__typename === 'DefaultEntityUrl') &&
    'entity' in query.useDrupalRoute &&
    query.route.entity
  ) {
    drupalRoute.entity = query.useDrupalRoute.entity
    const result = { entity: query.route.entity as any, drupalRoute, head }
    app.callHook('drupal-route', result)
    return result
  }

  if (options?.noError) {
    const result = { entity: undefined, drupalRoute, head }
    app.callHook('drupal-route', result)
    return result
  }

  throw createError({
    statusCode: 404,
    statusMessage: 'Page not found',
    fatal: true,
  })
}

declare module '#app' {
  interface RuntimeNuxtHooks {
    'drupal-route': (data: UseDrupalRoute<any>) => HookResult
  }
}
